<template>
  <div class="auth-layout-wrap">
    <div class="auth-content">
      <div class="card o-hidden">
        <div class="row">
          <div class="col-md-6">
            <div class="p-4">
              <v-row justify="center" align="center">
                <v-col cols="12" sm="12">
                  <v-card elevation="0" class="mx-auto">
                    <div class="auth-logo text-center mt-10">
                      <img :src="logo" style="width: 100px" />
                    </div>
                    <h1 class="text-center">Register</h1>
                    <h3 class="text-center">
                      You have to be an active member of MNP to register an
                      account
                    </h3>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          label="Password"
                          type="password"
                          required
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="pa-4">
                        <v-btn
                          depressed
                          color="warning"
                          @click="gotoCreateUser"
                          class="pl"
                        >
                          CREATE ACCOUNT
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          depressed
                          color="success"
                          @click="signIn"
                          class="pr"
                        >
                          Login
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-overlay absolute :value="loading" :opacity="0.7">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </div>
          <div class="col-md-6">
            <img :src="bg" style="width: 100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      loading: false,
      id_card_no: "",
      idCardRules: [
        (v) => !!v || "ID Card is required",
        (v) => (v && v.length == 7) || "ID Card Number is 7 characters",
      ],
      mobile_no: "",
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => (v && v.length == 7) || "Mobile Number is 7 characters",
        (v) => (v && v >= 7777777 && v <= 9999999) || "Invalid Mobile Number",
      ],
      opt: "",
      optRules: [
        (v) => !!v || "OTP is required",
        (v) => (v && v.length == 6) || "OTP is 6 characters",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      logo: require("@/assets/images/logo.png"),
      bg: require("@/assets/images/bg.jpg"),
    };
  },
  methods: {
    signIn() {
      this.$refs.form.validate();
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
      };
      this.api("auth/login", f)
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response.status == true) {
            localStorage.setItem(
              "mnp_member_user",
              JSON.stringify(response.user)
            );
            localStorage.setItem("mnp_member_token", response.token);
            localStorage.setItem(
              "mnp_member_menu",
              JSON.stringify(response.menu)
            );
            document.location.href = "/";
          } else {
            this.handleError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    gotoLogin() {
      document.location.href = "/sign-in";
    },
  },
  name: "Register",
};
</script>
<style scoped>
.o-hidden {
  overflow: hidden;
}
.card {
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  border: 0;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
@media (min-width: 1024px) .auth-layout-wrap .auth-content {
  min-width: 660px;
}
.auth-layout-wrap .auth-content {
  max-width: 660px;
  margin: auto;
}
.auth-layout-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}
</style>
